import React, { useState, useEffect } from 'react';
import instance from '../../services/services';
import { INSPECTION_REPORT_URL } from "../../utils/urls";

// 동화검사 리포트 표지
const ReportCover = ({ reportData }) => {
    const [coverData, setCoverData] = useState(null);
    console.log(coverData, ':::coverData1');
    const [reportImage, setReportImage] = useState(null);

    useEffect(() => {
        const fetchCoverData = async () => {
            try {
                const response = await instance.post(INSPECTION_REPORT_URL, {
                    report_seq: reportData.report_seq
                });

                if (response.data.code === 200) {
                    setCoverData(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching cover data:", error);
            }
        };

        const loadReportImage = async () => {
            try {
                const imagePath = await import(`../../assets/report1/${reportData.sort_num}.png`)
                    .then(module => module.default);
                setReportImage(imagePath);
            } catch (error) {
                console.log('report1 이미지를 찾을 수 없습니다.');
            }
        };

        if (reportData.report_seq) {
            fetchCoverData();
        }
        if (reportData.sort_num) {
            loadReportImage();
        }
    }, [reportData.report_seq, reportData.sort_num]);

    const textStyle = {
        position: 'absolute',
        color: '#000',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
        padding: '5px 10px',
        background: 'transparent',
        width: '200px'
    };

    return (
        <div className="report-cover-container" style={{ 
            position: 'relative',
            width: '595px',
            height: '842px',
            margin: '0 auto'
        }}>
            {reportImage && (
                <img 
                    src={reportImage}
                    alt="동화 검사 리포트 표지" 
                    style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            )}
            
            {coverData && (
                <div className="overlay-data" style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%' 
                }}>
                    {/* 검사일 */}
                    <div style={{
                        ...textStyle, 
                        top: '83%',
                        left: '29%',
                        width: '150px',
                    }}>
                        {coverData.report_info?.complete_date ? 
                            new Date(coverData.report_info.complete_date)
                                .toLocaleDateString('ko-KR')
                                .replace(/\. /g, '.')
                                .replace(/\.$/, '') // 마지막 마침표 제거
                            : 
                            new Date()
                                .toLocaleDateString('ko-KR')
                                .replace(/\. /g, '.')
                                .replace(/\.$/, '') // 마지막 마침표 제거
                        }
                    </div>

                    {/* 이름 */}
                    <div style={{
                        ...textStyle,
                        top: '75.3%',
                        left: '65%',
                        width: '150px',
                    }}>
                        {coverData.report_info?.test_user_name || ''}
                    </div>

                    {/* 성별 */}
                    <div style={{
                        ...textStyle,
                        top: '79.3%',
                        left: '65%',
                        width: '150px',
                    }}>
                        {coverData.report_info?.gender === 'male' ? (
                            <span style={{ color: '#000' }}>남자</span>
                        ) : coverData.report_info?.gender === 'female' ? (
                            <span style={{ color: '#000' }}>여자</span>
                        ) : null}
                    </div>

                    {/* 연령 */}
                    <div style={{
                        ...textStyle,
                        top: '83%',
                        left: '65%',
                        width: '150px',
                    }}>
                        {coverData.report_info?.age_txt ? `만 ${coverData.report_info.age_txt}세` : ''}
                    </div>
                </div>
            )}
        </div>
    );
};

const CoverFairytale = ({ reportData, listData }) => {
    return (
        <div className="report-container">
            <ReportCover reportData={reportData} />
        </div>
    );
};

export default CoverFairytale;