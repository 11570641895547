import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import CoverReport from '../../components/Roport/CoverReport';
import CoverFairytale from '../../components/Roport/CoverFairytale';
import generalCoverImage from '../../assets/report_user/general_cover.jpg';

// 동적으로 Report 컴포넌트 로드하는 함수
const loadReportComponent = async (sortNum) => {
    console.log(sortNum, ':::sortNum');
    try {
        // Report 컴포넌트 동적 로드
        // const ReportModule = await import(`../Report/Report/${sortNum}`);
        const ReportModule = await import(`../../pages/Report/Report/${sortNum}`);
        return ReportModule.default;
    } catch (error) {
        console.error(`Report ${sortNum} 로드 실패:`, error);
        return null;
    }
    };

    // 동적으로 ReportDetail 컴포넌트 로드하는 함수
    const loadReportDetailComponent = async (sortNum) => {
    try {
        // ReportDetail 컴포넌트 동적 로드
        const ReportDetailModule = await import(`../Report/ReportDetail/${sortNum}`);
        return ReportDetailModule.default;
    } catch (error) {
        console.error(`ReportDetail ${sortNum} 로드 실패:`, error);
        return null;
    }
    };

    // 일반/종합검사 리포트 표지
    function MyReportList({ listData }) {
    console.log(listData, ':::listData');
    const handleDownloadPDF = async (item) => {
        // testSortNum 제거하고 item의 실제 sort_num 사용
        const modifiedItem = {
            ...item,
            sort_num: item.sort_num 
        };

        console.log(modifiedItem, ':::modifiedItem');
        
        // story 타입이 아닌 경우의 로직
        if (item.goods_type !== 'story') {
        const pdf = new jsPDF('p', 'mm', 'a4');

        try {
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.top = '-9999px';
            document.body.appendChild(tempDiv);

            // 표지 페이지 렌더링
            ReactDOM.render(<CoverReport reportData={modifiedItem} listData={listData} />, tempDiv);

            await new Promise((resolve) => setTimeout(resolve, 1000));

            // 표지 페이지 캡처
            const coverCanvas = await html2canvas(tempDiv, {
            scale: 2,
            useCORS: true,
            logging: false,
            backgroundColor: null,
            });

            const coverImgData = coverCanvas.toDataURL('image/png');
            const coverImgWidth = 210;
            const coverImgHeight = (coverCanvas.height * coverImgWidth) / coverCanvas.width;

            // 표지 페이지 추가
            pdf.addImage(coverImgData, 'PNG', 0, 0, coverImgWidth, coverImgHeight);

            // 일반검사 타입일 경우 두 번째 페이지에 일반검사표지 추가
            if (item.goods_type === 'general') {
            pdf.addPage();
            const img = new Image();
            img.src = generalCoverImage;

            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });

            const imgCanvas = document.createElement('canvas');
            const imgCtx = imgCanvas.getContext('2d');
            imgCanvas.width = img.width;
            imgCanvas.height = img.height;
            imgCtx.drawImage(img, 0, 0);

            const imgData = imgCanvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 크기로 설정
            }

            pdf.save(`${item.goods_name}_report.pdf`);
            document.body.removeChild(tempDiv);
        } catch (error) {
            console.error('PDF 생성 중 오류:', error);
            alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
        }
        return;
        }

        // story 타입인 경우의 로직
        const pdf = new jsPDF('p', 'mm', 'a4');

        try {
        // Report 컴포넌트 미리 렌더링
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.left = '-9999px';
        tempDiv.style.top = '-9999px';
        document.body.appendChild(tempDiv);

        // Report 컴포넌트 로드 시 실제 sort_num 사용
        const ReportComponent = await loadReportComponent(item.sort_num);
        if (!ReportComponent) {
            throw new Error(`Report 컴포넌트를 찾을 수 없습니다: ${item.sort_num}`);
        }

        ReactDOM.render(
            <ReportComponent
            reportData={modifiedItem} // 수정된 item 사용
            listData={listData}
            />,
            tempDiv
        );

        await new Promise((resolve) => setTimeout(resolve, 1000));

        const reportCanvas = await html2canvas(tempDiv, {
            scale: 2,
            useCORS: true,
            logging: false,
            backgroundColor: null,
            windowWidth: document.body.scrollWidth,
            windowHeight: tempDiv.scrollHeight,
            onclone: (clonedDoc) => {
            const splitHeight = Math.floor(tempDiv.scrollHeight * 0.7);
            const elements = clonedDoc.getElementsByTagName('*');

            Array.from(elements).forEach((element) => {
                const rect = element.getBoundingClientRect();
                const elementBottom = rect.bottom;
                const elementTop = rect.top;

                // 분할 지점 주변 (위아래 100px) 요소들만 처리
                if (Math.abs(elementBottom - splitHeight) < 100 || Math.abs(elementTop - splitHeight) < 100) {
                // 기존 스타일 저장
                const originalBorder = element.style.border;
                const originalBackground = element.style.backgroundImage;

                // PDF 생성을 위한 임시 스타일 적용
                element.style.border = 'none';
                element.style.borderTop = 'none';
                element.style.borderBottom = 'none';

                // 분할 지점에서만 background-image 제거
                if (Math.abs(elementBottom - splitHeight) < 50) {
                    element.style.backgroundImage = 'none';
                }

                // 나머지 부분은 원래 스타일 유지
                setTimeout(() => {
                    element.style.border = originalBorder;
                    element.style.backgroundImage = originalBackground;
                }, 0);
                }
            });
            },
        });

        const reportImgData = reportCanvas.toDataURL('image/png');
        const reportImgWidth = 210 * 1;
        const reportImgHeight = (reportCanvas.height * reportImgWidth) / reportCanvas.width;
        const xOffset = (210 - reportImgWidth) / 2;
        const pageHeight = 297; // A4 높이 (mm)

        // 1페이지: report1 이미지
        try {
            // CoverFairytale 컴포넌트 렌더링
            ReactDOM.render(<CoverFairytale reportData={modifiedItem} listData={listData} />, tempDiv);

            await new Promise((resolve) => setTimeout(resolve, 1000));

            // 표지 페이지 캡처
            const coverCanvas = await html2canvas(tempDiv, {
            scale: 2,
            useCORS: true,
            logging: false,
            backgroundColor: null,
            });

            const coverImgData = coverCanvas.toDataURL('image/png');
            const coverImgWidth = 210;
            const coverImgHeight = (coverCanvas.height * coverImgWidth) / coverCanvas.width;

            // PDF에 이미지 추가
            pdf.addImage(coverImgData, 'PNG', 0, 0, coverImgWidth, coverImgHeight);
        } catch (error) {
            console.log('report_1 이미지를 찾을 수 없습니다.');
        }

        // 2페이지: report 이미지
        pdf.addPage();
        try {
            // 이미지 로드 시 실제 sort_num 사용
            const imagePath = await import(`../../assets/report/${item.sort_num}.png`);
            const img = new Image();
            img.src = imagePath.default;

            await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            });

            const imgCanvas = document.createElement('canvas');
            const imgCtx = imgCanvas.getContext('2d');
            const a4WidthInPixels = 210 * 3.78;
            imgCanvas.width = a4WidthInPixels;
            imgCanvas.height = (img.height * imgCanvas.width) / img.width;
            imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

            const imgData = imgCanvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
        } catch (error) {
            console.log('report 이미지를 찾을 수 없습니다.');
        }

        // 3페이지와 4페이지에 Report 컴포넌트 표시
        pdf.addPage();

        try {
            const threshold = 20; // 20mm 정도의 여유 공간
            const totalPages = Math.ceil((reportImgHeight - threshold) / pageHeight);
            
            // 각 페이지별로 내용 분할
            for (let i = 0; i < totalPages; i++) {
                if (i > 0) pdf.addPage();
                
                const yStart = i * pageHeight;
                
                pdf.addImage(
                    reportImgData,
                    'PNG',
                    xOffset,
                    -yStart, // 음수값으로 설정하여 이미지 위치 조정
                    reportImgWidth,
                    reportImgHeight
                );
            }
        } catch (error) {
            console.error('Report 페이지 생성 중 오류:', error);
            alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
        }

        // 4페이지 (결과 상세)
        try {
            const ReportDetailComponent = await loadReportDetailComponent(item.sort_num);
            if (ReportDetailComponent) {
                pdf.addPage();
                ReactDOM.render(<ReportDetailComponent reportData={modifiedItem} />, tempDiv);

                await new Promise((resolve) => setTimeout(resolve, 1000));

                // 결과 상세 페이지 캡처
                const detailCanvas = await html2canvas(tempDiv, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                    backgroundColor: null,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: tempDiv.scrollHeight,
                });

                const detailImgData = detailCanvas.toDataURL('image/png');
                const detailImgWidth = 210;
                const detailImgHeight = (detailCanvas.height * detailImgWidth) / detailCanvas.width;

                // 한 페이지 높이 (297mm)를 기준으로 필요한 페이지 수 계산
                const pageHeight = 297;
                const totalPages = Math.ceil(detailImgHeight / pageHeight);

                // 각 페이지별로 내용 분할
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) pdf.addPage();

                    const yStart = i * pageHeight;
                    pdf.addImage(
                        detailImgData,
                        'PNG',
                        0,
                        -yStart,
                        detailImgWidth,
                        detailImgHeight
                    );
                }
            }
        } catch (error) {
            console.log('결과 상세 페이지를 찾을 수 없습니다.');
        }
        
        // 4-1페이지 (결과 상세2)
        try {
            const ReportDetail2Component = await import(`../Report/ReportDetail2/${item.sort_num}`);
            if (ReportDetail2Component) {
                pdf.addPage();
                ReactDOM.render(<ReportDetail2Component reportData={modifiedItem} />, tempDiv);

                await new Promise((resolve) => setTimeout(resolve, 1000));

                // 결과 상세 페이지 캡처
                const detail2Canvas = await html2canvas(tempDiv, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                    backgroundColor: null,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: tempDiv.scrollHeight,
                });

                const detail2ImgData = detail2Canvas.toDataURL('image/png');
                const detail2ImgWidth = 210;
                const detail2ImgHeight = (detail2Canvas.height * detail2ImgWidth) / detail2Canvas.width;

                // 한 페이지 높이 (297mm)를 기준으로 필요한 페이지 수 계산
                const pageHeight = 297;
                const totalPages = Math.ceil(detail2ImgHeight / pageHeight);

                // 각 페이지별로 내용 분할
                for (let i = 0; i < totalPages; i++) {
                    if (i > 0) pdf.addPage();

                    const yStart = i * pageHeight;
                    pdf.addImage(
                        detail2ImgData,
                        'PNG',
                        0,
                        -yStart,
                        detail2ImgWidth,
                        detail2ImgHeight
                    );
                }
            }
        } catch (error) {
            console.log('결과 상세2 페이지를 찾을 수 없습니다.');
        }
        

        // 5페이지: report2 이미지
        pdf.addPage();
        try {
            // report2 이미지도 실제 sort_num 사용
            const report2Path = await import(`../../assets/report2/${item.sort_num}.png`);
            const img = new Image();
            img.src = report2Path.default;

            await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            });

            const imgCanvas = document.createElement('canvas');
            const imgCtx = imgCanvas.getContext('2d');
            const a4WidthInPixels = 210 * 3.78;
            imgCanvas.width = a4WidthInPixels;
            imgCanvas.height = (img.height * imgCanvas.width) / img.width;
            imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

            const imgData = imgCanvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
        } catch (error) {
            console.log('report2 이미지를 찾을 수 없습니다.');
        }

        // 6페이지: report3 이미지
        pdf.addPage();
        try {
            // report3 이미지도 실제 sort_num 사용
            const report3Path = await import(`../../assets/report3/${item.sort_num}.png`);
            const img = new Image();
            img.src = report3Path.default;

            await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            });

            const imgCanvas = document.createElement('canvas');
            const imgCtx = imgCanvas.getContext('2d');
            const a4WidthInPixels = 210 * 3.78;
            imgCanvas.width = a4WidthInPixels;
            imgCanvas.height = (img.height * imgCanvas.width) / img.width;
            imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

            const imgData = imgCanvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
        } catch (error) {
            console.log('report3 이미지를 찾을 수 없습니다.');
        }

        pdf.save(`${item.goods_name}_report.pdf`);

        document.body.removeChild(tempDiv);
        } catch (error) {
        console.error('PDF 생성 중 오류:', error);
        alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className="forward_type my_list">
        {listData.map((item, index) => (
            <div className="assessment_info childrenDetail" key={`${item.goods_seq}-${index}`}>
            <div className="assessment_details">
                <div className="assessment_title ellipsis1">{item.goods_name}</div>
                <div className="assessment_meta group_line" style={{ paddingTop: '6px' }}>
                {item?.story_title_sub && <div className="assessment_code group_item">{item.story_title_sub}</div>}
                {item?.inspection_cnt && <div className="assessment_code group_item">{item.inspection_cnt}회차</div>}
                </div>
                <button type="button" className="resend_button" onClick={() => handleDownloadPDF(item)}>
                PDF 다운받기
                </button>
            </div>
            <div className="children_image_container">
                <img className="Children_Img" src={item.list_image} alt="Children_Img" />
            </div>
            </div>
        ))}
        </div>
    );
    }

export default MyReportList;

