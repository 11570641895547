import { useEffect, useState } from 'react';
import instance from '../services';
import { INSPECTION_REPORT_TEST_URL } from '../../utils/urls';

export const useReportData = (reportData) => {
    const [reportResult, setReportResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReportResult = async () => {
            setLoading(true);
            try {
                const response = await instance.post(INSPECTION_REPORT_TEST_URL, {
                    sort_num: reportData?.sort_num
                });
                setReportResult(response.data);
                console.log(response.data, ':::response');
            } catch (error) {
                console.error(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (reportData?.sort_num) {
            fetchReportResult();
        }
    }, [reportData]);

    return { reportResult, loading, error };
};