import React from 'react';
import '../../styles/pages/report.css';
import '../../styles/pages/reportDetail.css';

function Report() {
  return (
    <div className="view-report">
      <div className="report-content">
        <div className="report-wrapper rw01">
          <div className="report-section chart-section">
            <div className="report-title">
              <div className="title-icon1"></div>하위 영역별 상세 결과<div className="title-icon2"></div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw02">
          <div className="report-border">
            <div className="report-section table-section">
              {/* <div className="report-table reportC2"> */}
              <div className="reportDetail-table detailE3">
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>교육관</th>
                        <th>해설</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={4}>
                          1.
                          <br /> 교육
                          <br />
                          방식
                          <br />
                          영역
                        </td>
                      </tr>
                      <tr>
                        <td>
                          주<br />
                          양육자
                        </td>
                        <td></td>
                        <td className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          보조
                          <br />
                          양육자
                        </td>
                        <td></td>
                        <td className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          비교
                          <br />
                          및
                          <br />
                          코멘트
                        </td>
                        <td colSpan={2} className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td rowSpan={4}>
                          2.
                          <br /> 교육
                          <br />
                          내용
                          <br />
                          영역
                        </td>
                      </tr>
                      <tr>
                        <td>
                          주<br />
                          양육자
                        </td>
                        <td></td>
                        <td className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          보조
                          <br />
                          양육자
                        </td>
                        <td></td>
                        <td className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          비교
                          <br />
                          및
                          <br />
                          코멘트
                        </td>
                        <td colSpan={2} className="line150">
                          스트레스 상황을 해소하기 위해 손톱을 물어뜯음으로써 스트레스 완화, 불안 감소 등 긍정적인
                          정서가 일시적으로 유발되는 것을 돕습니다.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw03"></div>
      </div>
    </div>
  );
}
export default Report;
