import React, { useRef, useEffect, useMemo } from 'react';
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function ReportB1() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // 하드코딩된 차트 데이터를 useMemo로 감싸기
  const data = useMemo(
    () => ({
      labels: [0, 0, 0, 0],
      datasets: [
        {
          data: [85, 75, 90, 70, 80],
          backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
          borderWidth: 0,
        },
      ],
    }),
    []
  ); // 빈 의존성 배열은 데이터가 변경되지 않음을 의미

  // customPlugin은 그대로 유지
  const customPlugin = useMemo(
    () => ({
      id: 'moveBarsToFront',
      afterDatasetsDraw(chart) {
        chart.data.datasets.forEach((dataset, index) => {
          if (chart.isDatasetVisible(index)) {
            const meta = chart.getDatasetMeta(index);
            if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
              meta.dataset.draw(chart.ctx);
            }
            meta.data.forEach((element) => {
              if (element && typeof element.draw === 'function') {
                element.draw(chart.ctx);
              }
            });
          }
        });
      },
    }),
    []
  );

  // 차트 초기화
  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(1, 'rgba(230,245,252,1)');

    const config = {
      type: 'bar',
      data: data,
      options: {
        devicePixelRatio: window.devicePixelRatio,
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: {
            display: false,
            text: '유 · 아동 교조증 행동 검사 결과',
          },
          annotation: {
            annotations: [
              {
                type: 'box',
                xMin: 0,
                xMax: 100,
                backgroundColor: gradient,
                borderWidth: 0,
                label: {
                  content: '',
                  enabled: true,
                  position: 'center',
                },
              },
            ],
          },
        },
        scales: {
          x: {
            min: 0,
            max: 100,
            stepSize: 10,
            title: { display: false },
            ticks: {
              stepSize: 10,
              beginAtZero: true,
              font: {
                size: 10, // x축 숫자 폰트 크기 조절
              },
            },
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10, // y축 숫자 폰트 크기 조절
              },
            },
          },
        },
      },
      plugins: [customPlugin],
    };

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, customPlugin]);


  return (
    <div className="view-report">
      <div className="report-content">
        <div className="report-wrapper rw01">
          <div className="report-section chart-section">
            <div className="report-title">
              <div className="title-icon1"></div>종합 결과<div className="title-icon2"></div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw02">
          <div className="report-border">
            <div className="report-result">
              <div className="report-result-title">유 · 아동 교조증 행동 검사 결과</div>
              <div className="report-graph">
                <canvas ref={chartRef} className="chart-canvas" width="400"></canvas>
              </div>
              <div className="report-legend" >
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#FFE3A8' }}></div>
                  <div className="legend-label">종합</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#66DFB8' }}></div>
                  <div className="legend-label">긍정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#72C0FF' }}></div>
                  <div className="legend-label">행동 유발 환경 영역 </div>
                </div>
              </div>
            </div>
            <div className="report-class">
              <span>31 ~ 52 낮은 수준 / </span>
              <span>53 ~ 80 보통 수준 / </span>
              <span>81 ~ 100 높은 수준 </span>
            </div>
            {/* 상하위 테이블 부모 */}
            <div className="report-section table-section">
              {/* report-table는 공통클래스 */}
              {/* 상위 테이블 */}
              <div className="report-table reportB1">
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th>발달 지능</th>
                        <th>원점수</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>자존감 기능</td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>
                          자기 조절
                          <br /> 기능
                        </td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>긍정성 지능</td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>사회성 지능</td>
                        <td>7</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th>발달 지능</th>
                        <th>원점수</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>호기심 기능</td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>창의성 지능</td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>
                          공간 지각 <br /> 지능
                        </td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>음악 지능</td>
                        <td>7</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th>발달 지능</th>
                        <th>원점수</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>언어 지능</td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>
                          논리 수학
                          <br /> 기능
                        </td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>
                          자연 탐구
                          <br /> 지능
                        </td>
                        <td>7</td>
                      </tr>
                      <tr>
                        <td>
                          신체 운동
                          <br /> 지능
                        </td>
                        <td>7</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* 하위 테이블 */}
              {/* 하위 테이블 미표출시, under-reportB1가 안보이게 해주면 됩니다 */}
              <div className="report-table under-reportB1">
                <div className="box_w"></div>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>1순위</th>
                      <th>2순위</th>
                      <th>3순위</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>강점 영역</td>
                      <td>신체 운동 지능</td>
                      <td>논리수학 지능</td>
                      <td>긍정성 지능</td>
                      <td>입니다.</td>
                    </tr>
                    <tr>
                      <td>보완 영역</td>
                      <td>음악 지능</td>
                      <td>자기 조절 지능</td>
                      <td>자존감 지능</td>
                      <td>입니다.</td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        아동의 강점 지능 검사 결과는 다음과 같으며, 하단의 해설을 기반으로 아이 교육에 활용해 보세요.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw03"></div>
      </div>
    </div>
  );
}
export default ReportB1;
