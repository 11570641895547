import React, { useRef, useEffect, useMemo } from 'react';
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function ReportB3() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // 하드코딩된 차트 데이터를 useMemo로 감싸기
  const data = useMemo(
    () => ({
      labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      datasets: [
        {
          data: [85, 75, 90, 70, 80, 80, 85, 75, 90, 70, 80, 90],
          backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
          borderWidth: 0,
        },
      ],
    }),
    []
  ); // 빈 의존성 배열은 데이터가 변경되지 않음을 의미

  // customPlugin은 그대로 유지
  const customPlugin = useMemo(
    () => ({
      id: 'moveBarsToFront',
      afterDatasetsDraw(chart) {
        chart.data.datasets.forEach((dataset, index) => {
          if (chart.isDatasetVisible(index)) {
            const meta = chart.getDatasetMeta(index);
            if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
              meta.dataset.draw(chart.ctx);
            }
            meta.data.forEach((element) => {
              if (element && typeof element.draw === 'function') {
                element.draw(chart.ctx);
              }
            });
          }
        });
      },
    }),
    []
  );

  // 차트 초기화
  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(1, 'rgba(230,245,252,1)');

    const config = {
      type: 'bar',
      data: data,
      options: {
        devicePixelRatio: window.devicePixelRatio,
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: {
            display: false,
            text: '유 · 아동 교조증 행동 검사 결과',
          },
          annotation: {
            annotations: [
              {
                type: 'box',
                xMin: 0,
                xMax: 100,
                backgroundColor: gradient,
                borderWidth: 0,
                label: {
                  content: '',
                  enabled: true,
                  position: 'center',
                },
              },
            ],
          },
        },
        scales: {
          x: {
            min: 0,
            max: 100,
            stepSize: 10,
            title: { display: false },
            ticks: {
              stepSize: 10,
              beginAtZero: true,
              font: {
                size: 10, // x축 숫자 폰트 크기 조절
              },
            },
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10, // y축 숫자 폰트 크기 조절
              },
              // 라벨이 잘릴 경우 아래 옵션 추가
              callback: function (value, index) {
                return this.getLabelForValue(value);
              },
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        },
      },
      plugins: [customPlugin],
    };

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, customPlugin]);

  return (
    <div className="view-report">
      <div className="report-content">
        <div className="report-wrapper rw01">
          <div className="report-section chart-section">
            <div className="report-title">
              <div className="title-icon1"></div>종합 결과<div className="title-icon2"></div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw02">
          <div className="report-border">
            <div className="report-result">
              <div className="report-result-title">유 · 아동 교조증 행동 검사 결과</div>
              <div className="report-graph">
                <canvas ref={chartRef} className="chart-canvas" width="400"></canvas>
              </div>
              <div className="report-legend">
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#FFE3A8' }}></div>
                  <div className="legend-label">종합</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#66DFB8' }}></div>
                  <div className="legend-label">긍정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
                <div className="legend-item">
                  <div className="legend-color-box" style={{ backgroundColor: '#C015AC' }}></div>
                  <div className="legend-label">부정 유발 정서 영역</div>
                </div>
              </div>
            </div>
              <div className="report-class">
                    <span>(유 · 아동 관점) 31 ~ 50 적정 양육 행동 인지</span>
                    <span>51 ~ 80 보호적 양육 행동 인지</span>
                    <span>81 ~ 100 과보호적 양육 행동 인지</span>
                    <span>(양육자 관점) 31 ~ 50 적정 양육 태도</span>
                    <span>51 ~ 80 보호적 양육 태도</span>
                    <span>81 ~ 100 과보호적 양육 태도</span>
                </div>
            <div className="report-section table-section">
              <div className="report-table reportB1">
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>원점수</th>
                        <th>백분율(%)</th>
                        <th>수준</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          공간 <br /> 지각력
                        </td>
                        <td>9</td>
                        <td>100</td>
                        <td>
                          높은
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>수리력</td>
                        <td>4</td>
                        <td>44</td>
                        <td>
                          다소
                          <br />
                          낮은
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>어휘력</td>
                        <td>9</td>
                        <td>100</td>
                        <td>
                          높은
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>추리력</td>
                        <td>9</td>
                        <td>100</td>
                        <td>
                          높은
                          <br />
                          수준
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table_box">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>원점수</th>
                        <th>백분율(%)</th>
                        <th>수준</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>기억력</td>
                        <td>7</td>
                        <td>100</td>
                        <td>
                          또래
                          <br />
                          평균
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>실행력</td>
                        <td>7</td>
                        <td>100</td>
                        <td>
                          또래
                          <br />
                          평균
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>집중력</td>
                        <td>4</td>
                        <td>44</td>
                        <td>
                          다소
                          <br />
                          낮은
                          <br />
                          수준
                        </td>
                      </tr>
                      <tr>
                        <td>
                          학습
                          <br />
                          동기
                        </td>
                        <td>7</td>
                        <td>100</td>
                        <td>
                          또래
                          <br />
                          평균
                          <br />
                          수준
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* 아래 테이블 미표출시, under-reportB3가 안보이게 해주면 됩니다 */}
              <div className="report-table under-reportB3">
                <div className="box_w"></div>
                <div className="summary">아동의 학습 능력 검사 결과</div>
                <table>
                  <tr>
                    <td>학습 강점 영역</td>
                    <td>공간지각력</td>
                    <td>어휘력</td>
                  </tr>
                  <tr>
                    <td>보완 필요 영역</td>
                    <td>수리력</td>
                    <td>집중력</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="report-wrapper rw03"></div>
      </div>
    </div>
  );
}
export default ReportB3;
