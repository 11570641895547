import React, { useRef, useEffect, useMemo } from 'react';
import '../../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useReportData } from '../../../services/Report/report';

Chart.register(annotationPlugin);

function Report29({ reportData }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const { reportResult } = useReportData(reportData);
    console.log(reportResult?.data, ':::reportResult');

    const inspectionResults = useMemo(() => 
        reportResult?.data?.inspection_result || [], 
        [reportResult]
    );

    const getTotalResult = useMemo(() => {
        if (!inspectionResults.length) return null;
        
        // 데이터가 2개 이상일 경우 area_num이 "0"인 데이터가 종합
        if (inspectionResults.length >= 2) {
            return inspectionResults.find(result => result.area_num === "0");
        }
        // 데이터가 1개일 경우 area_num이 "1"인 데이터가 종합
        return inspectionResults.find(result => result.area_num === "1");
    }, [inspectionResults]);

    const getDetailResults = useMemo(() => {
        if (!inspectionResults.length) return [];
        // area_num이 "0"이 아닌 데이터만 필터링
        return inspectionResults.filter(result => result.area_num !== "0");
    }, [inspectionResults]);

    // 차트 데이터를 inspectionResults에서 가져오도록 수정
    const data = useMemo(
        () => ({
            labels: inspectionResults.map(result => result.score_percent || 0),
            datasets: [
                {
                    data: inspectionResults.map(result => result.score_percent || 0),
                    backgroundColor: ['#FFE3A8', '#66DFB8', '#C015AC', '#72C0FF'],
                    borderWidth: 0,
                },
            ],
        }),
        [inspectionResults]
    ); // inspectionResults가 변경될 때마다 차트 데이터 업데이트

    // customPlugin은 그대로 유지
    const customPlugin = useMemo(
        () => ({
        id: 'moveBarsToFront',
        afterDatasetsDraw(chart) {
            chart.data.datasets.forEach((dataset, index) => {
            if (chart.isDatasetVisible(index)) {
                const meta = chart.getDatasetMeta(index);
                if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
                meta.dataset.draw(chart.ctx);
                }
                meta.data.forEach((element) => {
                if (element && typeof element.draw === 'function') {
                    element.draw(chart.ctx);
                }
                });
            }
            });
        },
        }),
        []
    );

    // 차트 초기화
    useEffect(() => {
        if (!chartRef.current) return;

        if (chartInstance.current) {
        chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
        gradient.addColorStop(0, 'rgba(255,255,255,1)');
        gradient.addColorStop(1, 'rgba(230,245,252,1)');

        const config = {
        type: 'bar',
        data: data,
        options: {
            devicePixelRatio: window.devicePixelRatio,
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
            legend: { display: false },
            title: {
                display: false,
                text: '유 · 아동 교조증 행동 검사 결과',
            },
            annotation: {
                annotations: [
                {
                    type: 'box',
                    xMin: 0,
                    xMax: 100,
                    backgroundColor: gradient,
                    borderWidth: 0,
                    label: {
                    content: '',
                    enabled: true,
                    position: 'center',
                    },
                },
                ],
            },
            },
            scales: {
            x: {
                min: 0,
                max: 100,
                stepSize: 10,
                title: { display: false },
                ticks: {
                stepSize: 10,
                beginAtZero: true,
                font: {
                    size: 10, // x축 숫자 폰트 크기 조절
                },
                },
                grid: {
                display: true,
                },
            },
            y: {
                display: true,
                grid: {
                display: false,
                },
                ticks: {
                font: {
                    size: 10, // y축 숫자 폰트 크기 조절
                },
                },
            },
            },
        },
        plugins: [customPlugin],
        };

        chartInstance.current = new Chart(ctx, config);

        return () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        };
    }, [data, customPlugin]);

    // const reportLegendStyle = {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    // };

    return (
        <div className="view-report">
        <div className="report-content">
            <div className="report-wrapper rw01">
            <div className="report-section chart-section">
                <div className="report-title">
                    <div className="title-icon1"></div>종합 결과<div className="title-icon2"></div>
                </div>
            </div>
            </div>
            <div className="report-wrapper rw02">
            <div className="report-border">
                <div className="report-result">
                <div className="report-result-title">{reportResult?.data?.inspection_info.inspection_title} 결과</div>
                <div className="report-graph">
                    <canvas ref={chartRef} className="chart-canvas" width="400" height="120"></canvas>
                </div>
                <div className="report-legend">
                    {inspectionResults.map((result, index) => (
                            <div className="legend-item" key={result.inspection_seq}>
                                <div 
                                    className="legend-color-box" 
                                    style={{ 
                                        backgroundColor: data.datasets[0].backgroundColor[index] 
                                    }}
                                />
                                <div className="legend-label">
                                    {result.inspection_name}
                                </div>
                            </div>
                        ))}
                </div>
                </div>
                <div className="report-class">
                    <span>31 ~ 50 낮은 수준</span>
                    <span>51 ~ 80 보통 수준</span>
                    <span>81 ~ 100 높은 수준</span>
                </div>
                {/* 상하위 테이블 부모  > A유형만 특이케이스로 상하위 테이블을 report-table에 합친 형태*/}
                <div className="report-section table-section">
                {/* report-table는 공통클래스 */}
                <div className="report-table reportA">
                    {/* 상위 테이블 */}
                    <div>
                    <table>
                        <thead>
                        <tr>
                            <th> </th>
                            <th>원점수</th>
                            <th>백분율(%)</th>
                            <th>수준</th>
                            <th>종합 해설</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="color_1">
                                {getTotalResult?.inspection_name || ''} 결과
                            </td>
                            <td>{getTotalResult?.total_score || 0}</td>
                            <td>{getTotalResult?.score_percent || 0}</td>
                            <td>{getTotalResult?.inspection_status || '-'}</td>
                            <td className="line150" style={{ padding: '0' }}>
                                <table className="inner-result-table" style={{ width: '100%', margin: '0' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ 
                                                padding: '10px',
                                                color: '#000',
                                                background: '#F7F7F7',
                                            }}>
                                                {getTotalResult?.summary || '데이터 없음'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ 
                                                padding: '15px',
                                                lineHeight: '1.6',
                                                background: '#F7F7F7',
                                                color: '#000',
                                            }}>
                                                {getTotalResult?.summary_detail || ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    {/* 하위 테이블 */}
                    <div>
                    <table>
                        <thead>
                            <tr>
                                <th> </th>
                                <th>원점수</th>
                                <th>백분율(%)</th>
                                <th>수준</th>
                                <th>하위 영역별 결과</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getDetailResults.map((result, index) => (
                                <tr key={index.inspection_seq}>
                                    <td>{result.inspection_name}</td>
                                    <td>{result.total_score}</td>
                                    <td>{result.score_percent}</td>
                                    <td>{result.inspection_status}</td>
                                        <td  className="line150">
                                            {result.summary}
                                        </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="report-wrapper rw03"></div>
        </div>
        </div>
    );
    }
export default Report29;
