import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {verifyPossession, updatePassword} from "../../services/Information/information";
import {passwordvisible} from "../../utils/passwordvisible";
import {numericRegex, validateInput} from "../../utils/validation";
import {toast} from "react-toastify";
import {CERTIFICATION_CHECK} from "../../utils/urls";
import instance from "../../services/services";
import {ERROR_MESSAGE} from "../../utils/constants";
import useUserStore from "../../store/user/userStore";

function PasswordChange() {
    const navigate = useNavigate();
    const errorMapping = {
        PASSWORD: false,
        PASSWORD_NOMATCH: false,
    }
    const {logout} = useUserStore()
    const [currentStep, setCurrentStep] = useState(1);
    const [userId, setUserId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(errorMapping)
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [isTimeOver, setIsTimeOver] = useState(false);
    const [timer, setTimer] = useState(120);
    const [certificationMsg, setCertificationMsg] = useState('');
    const [isShowPw1, setShowPw1] = useState(false);
    const [isShowPw2, setShowPw2] = useState(false);
    useEffect(() => {
        const areFieldsFilled = newPassword.trim() !== '' && confirmPassword.trim() !== '';
        const hasNoErrors = Object.values(error).every(value => !value);

        setIsErrorFree(areFieldsFilled && hasNoErrors);
    }, [error, newPassword, confirmPassword]);
    
    useEffect(() => {
        let interval = null;
        if (isResendDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);

        } else if (timer === 0) {
            setIsResendDisabled(false);
            setTimer(120);
            setIsTimeOver(true)

        }
        return () => clearInterval(interval);
    }, [isResendDisabled, timer]);


    const handleInputChange = (setter) => (e) => {
        if (e.target.name === 'certification') {
            const numericValue = numericRegex(e.target.value);
            return setter(numericValue)
        }
        setter(e.target.value)
    };

    const handleSendCode = async () => {
        try {
            if (!userId.trim() || !phoneNumber.trim()) {
                alert('아이디와 휴대폰 번호를 입력해주세요.');
                return;
            }
            setIsTimeOver(false)
            const response = await verifyPossession({
                userId: userId,
                phoneNumber: phoneNumber,
                idCheck: 'Y'
            });
            if (response.data.code === 200) {
                // 임시로 인증번호 전송 성공 처리
                setIsResendDisabled(true);
                setTimer(120);
                toast("인증번호가 발송되었습니다.");
            } else {
                navigate('/idfindfail?type=password');
            }

        } catch (error) {
            console.error('인증번호 발송 실패:', error);
        }
    };



    const handleBackClick = () => {
        window.history.back();
    };

    // 유효성 검사
    const isFirstStepValid =
        userId.trim().length > 0 &&
        phoneNumber.trim().length > 0 &&
        verificationCode.length === 6;
    const isVerifyButtonEnabled = userId.trim().length > 0 && phoneNumber.trim().length > 0;


    const checkPwUsage = () => {
        if (newPassword.length < 6 || newPassword.length > 15) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }
        if (!validateInput(newPassword, true)) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }

        return setError(errorMapping)
    }

    const checkPwConfirm = () => {
        if (confirmPassword && confirmPassword !== newPassword) {
            return setError((prev) => ({
                ...prev,
                PASSWORD_NOMATCH: true
            }));
        }
        if (confirmPassword && validateInput(newPassword, true)) {
            return setError(errorMapping)
        }
    }


    // 인증 번호 체크
    const checkCertification = async () => {
        try {
            // 1step:: 인증번호 체크
            const certificationRes = await instance.post(CERTIFICATION_CHECK, {
                cert_code: verificationCode,
                phoneNumber: phoneNumber
            });
            if (certificationRes.data.code === 200) {
                setCurrentStep(2);
            } else {
                setCertificationMsg(certificationRes.data.message);

            }
        } catch (e) {
            console.error(e)
        }
    }
    // 다음 단계 또는 비밀번호 변경 처리
    const handleNextClick = async () => {
        if (currentStep === 1) {
            await checkCertification()
        } else {
            try {
                if (newPassword !== confirmPassword) {
                    alert('비밀번호가 일치하지 않습니다.');
                    return;
                }

                const response = await updatePassword({
                    userId,
                    newPassword,
                    confirmPassword
                });

                if (response.data.code === 200) {
                    logout()
                    navigate('/newpassword/success');
                } else {
                    alert(response.data.message || "비밀번호 변경에 실패했습니다.");
                }
            } catch (error) {
                console.error('비밀번호 변경 오류:', error);
                alert(error.response?.data?.message || "비밀번호 변경에 실패했습니다.");
            }
        }
    };

    return (
        <div className="nicknameEdit">
            <div className="content">
                <Header title="비밀번호 변경" handleBack={handleBackClick}/>
                <div className="content_body">
                    {currentStep === 1 ? (
                        <>
                            <div className="nickname_instruction">아이디 및 휴대폰 번호를<br/>입력해 주세요</div>
                            <div className="nickname_input_container default_input">
                                <div className="nickname_label label">아이디</div>
                                <input
                                    type="text"
                                    className="nickname_input"
                                    value={userId}
                                    onChange={handleInputChange(setUserId)}
                                    placeholder="아이디 입력"
                                />

                                <div className="nickname_input_container default_input">
                                    <div className="nickname_label label">휴대폰 번호</div>
                                    <div style={{position: 'relative'}}>
                                        <input
                                            type="text"
                                            className="nickname_input"
                                            value={phoneNumber}
                                            name="certification"
                                            onChange={handleInputChange(setPhoneNumber)}
                                            placeholder="휴대폰 번호 입력"
                                        />
                                        <button
                                            className="resend_button"
                                            onClick={handleSendCode}
                                            disabled={isResendDisabled || !isVerifyButtonEnabled}
                                            style={{
                                                position: 'absolute',
                                                right: '8px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }}
                                        >
                                            인증번호 전송
                                        </button>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <input
                                            style={{marginTop: "8px"}}
                                            type="text"
                                            disabled={isTimeOver}
                                            className="nickname_input"
                                            maxLength={6}
                                            value={verificationCode}
                                            onChange={handleInputChange(setVerificationCode)}
                                            placeholder="인증번호 입력"
                                        />
                                        {isResendDisabled && (
                                            <div className="resend_time">
                                    <span>
                                        {Math.floor(timer / 60)}분 {String(timer % 60).padStart(2, '0')}초
                                    </span>
                                            </div>
                                        )}
                                        {isTimeOver && (
                                            <div className="resend_time">
                                    <span>
                                     인증번호 시간이 만료되었습니다.
                                    </span>
                                            </div>
                                        )}
                                        {
                                            certificationMsg && <div className="resend_time">
                                    <span>
                                     {certificationMsg}
                                    </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="nickname_instruction">새 비밀번호로 변경해 주세요</div>
                            <div className="nickname_input_container default_input">
                                <div className="nickname_label label">새 비밀번호</div>
                                <div className="comb_input">
                                    <input
                                        type={isShowPw1 ? 'text' : 'password'}
                                        className="login_input"
                                        value={newPassword}
                                        onBlur={() => {
                                            checkPwUsage();
                                            checkPwConfirm();
                                        }}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="6~15자의 영문/숫자/특수문자 조합"
                                    />
                                    <button
                                        type="button"
                                        className={`input_btn show_pw_btn ${isShowPw1 ? 'show' : ''}`}
                                        onClick={() => setShowPw1(!isShowPw1)}
                                        onMouseDown={(e) => passwordvisible(e, newPassword)}
                                    ></button>
                                </div>
                                {error.PASSWORD &&
                                    <div className={`error_message nickname`}>{ERROR_MESSAGE.PASSWORD}</div>}
                                <div className="comb_input">
                                    <input
                                        style={{marginTop: '8px'}}
                                        type={isShowPw2 ? 'text' : 'password'}
                                        className="login_input"
                                        value={confirmPassword}
                                        onBlur={checkPwConfirm}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="비밀번호 재입력"
                                    />
                                    <button
                                        type="button"
                                        className={`input_btn show_pw_btn ${isShowPw2 ? 'show' : ''}`}
                                        onClick={() => setShowPw2(!isShowPw2)}
                                        onMouseDown={(e) => passwordvisible(e, confirmPassword)}
                                        style={{marginTop: '4px'}}
                                    ></button>
                                </div>

                                {error.PASSWORD_NOMATCH && (
                                    <div className="error_message nickname">
                                        {ERROR_MESSAGE.PASSWORD_NOMATCH}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="Edit_btn_change">
                    <Button
                        label="비밀번호 변경"
                        variant="btn_next"
                        disabled={currentStep === 1 ? !isFirstStepValid : !isErrorFree}
                        className={currentStep === 1 ?
                            (isFirstStepValid ? 'active' : 'disabled') :
                            (isErrorFree ? 'active' : 'disabled')}
                        onClick={handleNextClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default PasswordChange;