import React, { useRef, useEffect, useMemo } from 'react';
import '../../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useReportData } from '../../../services/Report/report';
// import instance from '../../../services/services';
// import { INSPECTION_REPORT_TEST_URL } from '../../../utils/urls';

Chart.register(annotationPlugin);

function Report1({ reportData }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const { reportResult } = useReportData(reportData);
    console.log(reportResult?.data, ':::reportResult');
    const addInfo = reportResult?.data?.addInfo;

    const inspectionResults = useMemo(() => reportResult?.data?.inspection_result || [], [reportResult]);

    // 데이터를 4개씩 분할하는 함수
    const chunkArray = (array, size) => {
        const chunked = [];
        for (let i = 0; i < array.length; i += size) {
        chunked.push(array.slice(i, i + size));
        }
        return chunked;
    };

    // 4개씩 분할된 데이터 생성
    const chunkedResults = useMemo(() => chunkArray(inspectionResults, 4), [inspectionResults]);

    // 차트 데이터 생성
    const data = useMemo(
        () => ({
        // inspection_name을 labels로 사용
        labels: inspectionResults.map((result) => result.score_percent),
        datasets: [
            {
            // score_percent를 data로 사용
            data: inspectionResults.map((result) => result.score_percent),
            backgroundColor: [
                '#FFE3A8',
                '#66DFB8',
                '#C015AC',
                '#72C0FF',
                '#FF9F40',
                '#4BC0C0',
                '#FF6384',
                '#36A2EB',
                '#9966FF',
                '#FF99CC',
                '#4D5360',
                '#C9CBCF',
            ],
            borderWidth: 0,
            },
        ],
        }),
        [inspectionResults]
    );

    // customPlugin은 그대로 유지
    const customPlugin = useMemo(
        () => ({
        id: 'moveBarsToFront',
        afterDatasetsDraw(chart) {
            chart.data.datasets.forEach((dataset, index) => {
            if (chart.isDatasetVisible(index)) {
                const meta = chart.getDatasetMeta(index);
                if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
                meta.dataset.draw(chart.ctx);
                }
                meta.data.forEach((element) => {
                if (element && typeof element.draw === 'function') {
                    element.draw(chart.ctx);
                }
                });
            }
            });
        },
        }),
        []
    );

    // 차트 초기화
    useEffect(() => {
        if (!chartRef.current) return;

        if (chartInstance.current) {
        chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
        gradient.addColorStop(0, 'rgba(255,255,255,1)');
        gradient.addColorStop(1, 'rgba(230,245,252,1)');

        const config = {
        type: 'bar',
        data: data,
        options: {
            devicePixelRatio: window.devicePixelRatio,
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            // 데이터 개수에 따라 동적으로 높이 계산
            height: data.labels.length * 30, // 각 바의 높이를 30px로 계산
            plugins: {
            legend: { display: false },
            title: {
                display: false,
                text: `${reportResult?.data?.inspection_info.inspection_title} 결과`,
            },
            annotation: {
                annotations: [
                {
                    type: 'box',
                    xMin: 0,
                    xMax: 100,
                    backgroundColor: gradient,
                    borderWidth: 0,
                    label: {
                    content: '',
                    enabled: true,
                    position: 'center',
                    },
                },
                ],
            },
            },
            scales: {
            x: {
                min: 0,
                max: 100,
                stepSize: 10,
                title: { display: false },
                ticks: {
                stepSize: 10,
                beginAtZero: true,
                font: {
                    size: 10, // x축 숫자 폰트 크기 조절
                },
                },
                grid: {
                display: true,
                },
            },
            y: {
                display: true,
                grid: {
                    display: false,
                    },
                    ticks: {
                    font: {
                        size: 10, // y축 숫자 폰트 크기 조절
                    },
                    // 라벨이 잘릴 경우 아래 옵션 추가
                    callback: function (value, index) {
                        return this.getLabelForValue(value);
                    },
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    },
                },
            },
        },
        plugins: [customPlugin],
        };

        chartInstance.current = new Chart(ctx, config);

        return () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        };
    }, [data, customPlugin, reportResult?.data?.inspection_info.inspection_title]);

    // const reportLegendStyle = {
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // };

    return (
        <div className="view-report">
        <div className="report-content">
            <div className="report-wrapper rw01">
            <div className="report-section chart-section">
                <div className="report-title">
                <div className="title-icon1"></div>종합 결과<div className="title-icon2"></div>
                </div>
            </div>
            </div>
            <div className="report-wrapper rw02">
            <div className="report-border">
                <div className="report-result">
                <div className="report-result-title">{reportResult?.data?.inspection_info.inspection_title} 결과</div>
                <div className="report-graph">
                    <canvas ref={chartRef} className="chart-canvas" width="400"></canvas>
                </div>
                <div className="report-legend">
                    {inspectionResults.map((result, index) => (
                    <div className="legend-item" key={result.inspection_seq}>
                        <div
                        className="legend-color-box"
                        style={{
                            backgroundColor: data.datasets[0].backgroundColor[index],
                        }}
                        />
                        <div className="legend-label">{result.inspection_name}</div>
                    </div>
                    ))}
                </div>
                </div>

                {/* 상하위 테이블 부모 */}
                <div className="report-section table-section">
                {/* report-table는 공통클래스 */}
                {/* 상위 테이블 */}
                <div className="report-table reportB1">
                    {chunkedResults.map((chunk, index) => (
                    <div className="table_box" key={index}>
                        <table>
                        <thead>
                            <tr>
                            <th>발달 지능</th>
                            <th>원점수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chunk.map((result) => (
                            <tr key={result.inspection_seq}>
                                <td>
                                {result.inspection_name}
                                {/* 줄바꿈이 필요한 경우 처리 */}
                                {result.inspection_name.includes(' ') && <br />}
                                </td>
                                <td>{result.total_score}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    ))}
                </div>

                {/* 하위 테이블 */}
                <div className="report-table under-reportB1">
                    <div className="box_w"></div>
                    <table>
                    <thead>
                        <tr>
                        <th></th>
                        <th>1순위</th>
                        <th>2순위</th>
                        <th>3순위</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>강점 영역</td>
                        {addInfo?.top_scores?.map((score, index) => (
                            <td key={score.inspection_seq}>{score.inspection_name}</td>
                        ))}
                        <td>입니다.</td>
                        </tr>
                        <tr>
                        <td>보완 영역</td>
                        {addInfo?.bottom_scores?.map((score, index) => (
                            <td key={score.inspection_seq}>{score.inspection_name}</td>
                        ))}
                        <td>입니다.</td>
                        </tr>
                        <tr>
                        <td colSpan={5}>
                            아동의 강점 지능 검사 결과는 다음과 같으며, 하단의 해설을 기반으로 아이 교육에 활용해 보세요.
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <div className="report-wrapper rw03"></div>
        </div>
        </div>
    );
    }
export default Report1;