// 환경에 따른 기본 URL 설정
const LOCAL_DOMAIN_URL = "http://localhost:3000";
const MAIN_DOMAIN_URL = "https://www.iqvitamininside.com";
const PRODUCTION_DOMAIN_URL = "https://admin.iqvitamininside.com";

// 현재 URL에 따라 DOMAIN_URL 결정
const DOMAIN_URL = window.location.origin === LOCAL_DOMAIN_URL ? LOCAL_DOMAIN_URL : PRODUCTION_DOMAIN_URL;

//  KAKAO_REDIRECT_URL 결정
const KAKAO_URL = window.location.origin === LOCAL_DOMAIN_URL ? LOCAL_DOMAIN_URL : MAIN_DOMAIN_URL;

// API 기본 URL
export const BASE_API_URL = `${DOMAIN_URL}/api/`

// 메인페이지 동화검사 URL
export const MAIN_STORYLIST_URL = "api/main/story/list";

// 내 정보수정(닉네임 변경) URL
export const INFORMATION_NICKNAME_URL = "api/myInfoUpdate";

// 일반 회원가입 URL
export const JOIN_USERINFO_URL = "api/register";

// 비밀번호 변경 URL
export const INFORMATION_PASSWORD_URL = "api/passwordChange";

// 점유인증 URL
export const INFORMATION_VERIFY_URL = "api/certification";

// 아이디 찾기 URL
export const IDFIND_URL = "api/idFind";

// 점유인증 회원 가입
export const CERTIFICATION = "api/certification";

// 점유인증 확인
export const CERTIFICATION_CHECK = "api/certification_check";
// 비밀번호 찾기 URL
export const PASSWORDFIND_URL= "api/passwordFind";

// 검사 유형 상품 조회 URL(동화---packageInfoChk값으로 상품 조회와 전체 구매 구별) 
export const STORY_TEST_URL = "api/goods/getStoryPackageAll";

// 패키지 상품 조회
export const PACKAGE_TEST_URL = "api/goods/getStoryPackage";

// 단품 상품 조회
export const SINGLE_TEST_URL = "api/goods/getStorySingle";

// 장바구니에 담기 URL
export const CART_URL = "api/goods/cartInsert";

// 장바구니 리스트 URL
export const CART_LIST_URL = "api/goods/getGoodsCartList";

// 결제요청 URL
export const TOSS_PAYMENT_URL = "api/pay/request";

// 결제취소 URL
export const PAYCANCEL_URL = "api/pay/payCancel";

// 검사정보조회 URL
export const INSPECTION_INFO_URL = "api/inspection/inspectionInfo";

// 동화검사 문항조회 URL
export const INSPECTION_QUESTION_URL = "api/inspection/getStoryQuestions";

// 동화검사 답변완료시 저장한 값 보내는 URL
export const INSPECTION_ANSWER_URL = "api/inspection/saveStoryAnswer";

// 동화검사 재생시간 업데이트 URL
export const INSPECTION_UPDATE_TIME_URL = "api/inspection/updateVideoPlayTime";

// 동화검사 내 리포트 다운로드 URL
export const INSPECTION_REPORT_URL = "api/mypage/myReportDown";
// 동화검사 내 리포트 다운로드 테스트 URL
export const INSPECTION_REPORT_TEST_URL = "api/mypage/myReportDownTest";

// 무료체험 상품 URL
export const FREE_TEST_URL = "api/main/free";

// 일반검사 리스트 조회 URL
export const GENERAL_LIST_URL = "api/goods/getGoodsList";

// 일반 검사, 종합 검사 상품정보 조회 URL
export const GENERAL_GOODS_URL = "api/goods/getGoodsInfo";

// 종합 검사 리스트 조회 URL
export const SYNTHESIS_LIST_URL = "api/goods/getGoodsList";

// 일반/ 종합 검사 정보 입력시 URL 
export const GENERAL_SYNTHESIS_URL = "api/inspection/saveReportInfo";

// 일반 / 종합 검사 문항조회
export const SYNTHESIS_QUESTION_URL = "api/inspection/getQuestions";

// 일반 / 종합 검사 답변시
export const SYNTHESIS_ANSWER_URL = "api/inspection/saveAnswer";

// 토큰 요청 URL
export const TOKEN_URL = "api/auth";

// 로그인 요청 URL
export const LOGIN_INFO_URL =  "api/login";

// 카카오 회원가입
export const LOGIN_KAKAO_INFO_URL =  "api/auth/kakao/register";

// 네이버 회원가입
export const LOGIN_NAVER_INFO_URL = BASE_API_URL + "auth/naver/register";

// 애플 회원가입
export const APPLE_REGISTER_URL = `${BASE_API_URL}auth/apple/register`;


// 카카오 로그인 관련 상수
export const KAKAO_CLIENT_ID = "6508d5d97d5aad1eea9edcf169ba6d0f"; // REST API KEY
export const KAKAO_REDIRECT_URI = `${KAKAO_URL}/agreement`; // REDIRECT URL
export const KAKAO_LOGIN_REDIRECT_URI = `${KAKAO_URL}/login`; // REDIRECT URL
export const KAKAO_CLIENT_SECRET = "Mflt0hv6382OpzsOqTQUi4LxwIoMQw40"; // SECRET CODE
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&scope=openid`;

// 네이버 로그인 관련 상수
const generateState = () => Math.random().toString(36).substring(2); // 랜덤한 state 생성
export const NAVER_CLIENT_ID = '6eKJhvXgzRGaeaJyLE_B'; // 클라이언트 ID
export const NAVER_REDIRECT_URI = `${KAKAO_URL}/agreement`; // REDIRECT URL
export const NAVER_LOGIN_REDIRECT_URI = `${KAKAO_URL}/login`;
export const STATE = generateState(); // 생성된 랜덤 state 값

// 로그인 요청을 보낼 때 로컬 스토리지에 저장
export const setNaverAuthState = () => {
    localStorage.setItem('naver_auth_state', STATE);
};

export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${NAVER_REDIRECT_URI}`;

// Apple 로그인 관련 상수 
export const APPLE_CONFIG = {
    CLIENT_ID: 'com.iqvitamininside.service',
    SCOPE: 'name email',
    REDIRECT_URI: 'https://www.iqvitamininside.com/auth/apple/callback'
};

// Apple 로그인 URL 생성
export const APPLE_AUTH_URL = `https://appleid.apple.com/auth/authorize?${new URLSearchParams({
    client_id: APPLE_CONFIG.CLIENT_ID,
    redirect_uri: APPLE_CONFIG.REDIRECT_URI,
    response_type: APPLE_CONFIG.RESPONSE_TYPE,
    scope: APPLE_CONFIG.SCOPE,
    response_mode: APPLE_CONFIG.RESPONSE_MODE,
    state: crypto.randomUUID()
}).toString()}`;

